import { faker } from '@faker-js/faker';
import React, { memo } from 'react';

class Aid {
  /**
   * Pluralizes a word based on the count.
   * @param count - The number to determine singular or plural.
   * @param singular - The singular form of the word.
   * @param plural - The plural form of the word (optional).
   * @returns The pluralized word.
   */
  static pluralize = (
    count: number,
    singular: string,
    plural?: string
  ): string => {
    if (count === 1) {
      return `${count} ${singular}`;
    }
    return `${count} ${plural || singular + 's'}`;
  };
  static namePop = (url: string) => {
    if (this.isValidURL(url)) {
      return new URL(url).pathname.split('/').pop();
    }

    return '';
  };

  static displayFileName = (fileUpload: any) => {
    return fileUpload.display_name ?? fileUpload.file_name;
  };

  static getFullName = (user: {
    first_name: string;
    last_name?: string;
  }): string => {
    const firstName = user.first_name.trim();
    const lastName = user.last_name ? user.last_name.trim() : '';
    return [firstName, lastName].filter(Boolean).join(' ');
  };

  static isValidURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  static opacityHexMap: { [key: number]: string } = {
    0.1: '19', // 0.1 opacity → 19 in hex (approximately 25/255)
    0.2: '33', // 0.2 opacity → 33 in hex (approximately 51/255)
    0.3: '4C', // 0.3 opacity → 4C in hex (approximately 76/255)
    0.4: '66', // 0.4 opacity → 66 in hex (approximately 102/255)
    0.5: '7F', // 0.5 opacity → 7F in hex (approximately 127/255)
    0.6: '99', // 0.6 opacity → 99 in hex (approximately 153/255)
    0.7: 'B2', // 0.7 opacity → B2 in hex (approximately 178/255)
    0.8: 'CC', // 0.8 opacity → CC in hex (approximately 204/255)
    0.9: 'E5', // 0.9 opacity → E5 in hex (approximately 229/255)
    1.0: 'FF', // 1.0 opacity → FF in hex (255/255, or 100%)
  };
  static usStates = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };

  static param64 = (routeParams: { [key: string]: any }) => {
    return btoa(JSON.stringify(routeParams ?? {}));
  };

  static randomIntFromInterval = (min: number, max: number) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  static getRandomItem = (arr: string | any[]) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  static ucFirst(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static removeTags(str) {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str
      .replace(/(<([^>]+)>)/gi, '')
      .replace('&nbsp;', ' ')
      .replace(/\r?\n|\r/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  }

  // Add more utility functions here as static methods

  static isString = (value: unknown): value is string => {
    return typeof value === 'string' || value instanceof String;
  };

  static opacityToHex(opacity: number): string {
    const clampedOpacity = Math.min(Math.max(opacity, 0), 1); // Clamp between 0 and 1
    return Math.round(clampedOpacity * 255)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase();
  }

  static convertTo24HourFormat = (
    hour: string,
    minute: string,
    amPm: string
  ) => {
    let hourIn24 = parseInt(hour, 10); // Convert hour to number to avoid leading zeros issues
    const minuteFormatted = minute.toString().padStart(2, '0'); // Ensure minute is two digits

    if (amPm === 'pm' && hourIn24 < 12) {
      hourIn24 += 12; // Convert PM to 24-hour format by adding 12 (except for 12 PM)
    } else if (amPm === 'am' && hourIn24 === 12) {
      hourIn24 = 0; // Convert 12 AM to 00 in 24-hour format
    }

    // Ensure hour is two digits
    const hourFormatted = hourIn24.toString().padStart(2, '0');

    return `${hourFormatted}:${minuteFormatted}`;
  };

  static formatTime = (
    hour: string,
    minute: string,
    amPm: 'am' | 'pm',
    hourFormat: 12 | 24
  ): string => {
    // Check if both hour and minute are not empty
    if (hour && minute) {
      const timeSuffix = hourFormat === 12 ? ` ${amPm}` : '';
      return `${hour}:${minute} ${timeSuffix}`.trim();
    }
    return '';
  };

  static formatNumber(value: number | string, precision: number = 0): string {
    if (!value) return '';

    const num = Number(value);
    if (Number.isNaN(num)) {
      throw new Error(`Invalid number: ${value}`);
    }

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(num);
  }

  static fakeNumber(max = 49, min = 0, precision = 0): any {
    return Aid.formatNumber(faker.number.int({ min, max }), precision);
  }

  static fakeCurrency(max = 999, min = 0, precision = 0): any {
    return '$' + Aid.formatNumber(faker.finance.amount(min, max, 2), precision);
  }

  // Utility function to conditionally apply props.
  static conditionalProps(
    conditions: { [key: string]: boolean },
    props: { [key: string]: any }
  ) {
    return Object.keys(props).reduce((acc, key) => {
      if (conditions[key]) {
        acc[key] = props[key];
      }
      return acc;
    }, {});
  }

  /**
   * A utility function to conditionally apply props to a component.
   * It filters out props that are undefined or null.
   * @param props The props object.
   * @returns A new props object with only the props that have values.
   */
  static applyProps<T>(props: T): Partial<T> {
    const validProps: Partial<T> = {};

    Object.keys(props).forEach(key => {
      if (props[key] !== undefined && props[key] !== null) {
        validProps[key] = props[key];
      }
    });

    return validProps;
  }

  static gradientBg(color: string, direction = 'to-b'): string {
    return `bg-gradient-${direction} from-gradient-${color}-light to-gradient-${color}-dark`;
  }

  // Usage example:
  // const myComponentProps = applyProps({ id: 'my-component', className: undefined });
  // <MyComponent {...myComponentProps} />
}

export default Aid;

const ExampleConditionalProps = memo(() => {
  const tagProps = Aid.conditionalProps(
    {
      // Condition for href: it's applied if Tag is 'a' and item.href has a truthy value
      href: true,
      // Condition for className: it's applied if className is truthy (meaning it's provided and not an empty string)
      className: !!'className',
    },
    {
      // Actual href prop to apply if the condition is true
      href: 'https://www.google.com',
      // Actual className prop to apply if the condition is true
      className: 'className',
    }
  );

  return (
    <div>
      <a {...tagProps}>Link</a>
    </div>
  );
});

ExampleConditionalProps.displayName = 'ExampleConditionalProps';
