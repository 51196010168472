import useUserAccess from '@/Hooks/useUserAccess';
import React, { createContext } from 'react';

// Define the context with a default value
export const UserAccessContext = createContext(null);

// Create a provider component
export const UserAccessProvider = ({ children }) => {
  const userAccess = useUserAccess();

  return (
    <UserAccessContext.Provider value={userAccess}>
      {children}
    </UserAccessContext.Provider>
  );
};
