import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface SidebarState {
  sidebarExpanded: boolean;
  expandedSidebarItems: boolean[];
}

// Define the initial state using the `NavState` type
const initialState: SidebarState = {
  sidebarExpanded: true,
  expandedSidebarItems: [...Array(10).keys()].map(() => false),
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
      state.sidebarExpanded = action.payload;
    },
    setExpandedSidebarItems: (
      state,
      action: PayloadAction<[number, boolean]>
    ) => {
      const [index, value] = action.payload;
      // Directly assign the value to the specific index
      state.expandedSidebarItems[index] = value;
    },
  },
});
export const { setSidebarExpanded, setExpandedSidebarItems } =
  sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
