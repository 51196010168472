import { useSelector } from 'react-redux';
import { ReduxState } from '@/Redux/store';
import { useContext } from 'react';
import { SuperContext } from '@/Contexts/SuperContext';
import Aid from '@/Helpers/Aid';

export interface IUseUserAccess {
  ifAdmin: boolean;
  ifImpersonating: boolean;
  ifManaging: boolean;
  ifClient: boolean;
  ifClientOrImpersonating: boolean;
}

function useUserAccess() {
  const { auth_ } = useContext(SuperContext);
  const impData = useSelector((state: ReduxState) => state.impersonator.value);
  const userRole: unknown = auth_.user?.role ?? '';

  const adminRoles = ['master', 'admin', 'agent'];
  const clientRoles = ['client'];

  const _isAdmin =
    Aid.isString(userRole) && adminRoles.includes(userRole) && impData === null;
  const _isImpersonating =
    Aid.isString(userRole) && adminRoles.includes(userRole) && impData !== null;
  const _isManaging =
    Aid.isString(userRole) &&
    adminRoles.includes(userRole) &&
    impData === null &&
    window.route().current('company.*');
  const _isClient = Aid.isString(userRole) && clientRoles.includes(userRole);
  const _isClientOrImpersonating =
    Aid.isString(userRole) && (_isClient || _isImpersonating);

  return {
    ifAdmin: _isAdmin,
    ifImpersonating: _isImpersonating,
    ifManaging: _isManaging,
    ifClient: _isClient,
    ifClientOrImpersonating: _isClientOrImpersonating,
  } as IUseUserAccess;
}

export default useUserAccess;
