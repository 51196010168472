import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  IImpersonatorState,
  impersonatorReducer,
} from '@/Redux/Slices/impersonatorSlice';
import { sidebarReducer, SidebarState } from '@/Redux/Slices/sidebarSlice';

// RootState interface based on the reducers
export interface ReduxState {
  impersonator: IImpersonatorState;
  sidebar: SidebarState;
}

// Persist configurations
const impersonatorPersistConfig = {
  key: 'impersonator',
  storage,
};

const sidebarPersistConfig = {
  key: 'sidebar',
  storage,
};

const rootReducer = combineReducers({
  impersonator: persistReducer<IImpersonatorState>(
    impersonatorPersistConfig,
    impersonatorReducer
  ),
  sidebar: persistReducer<SidebarState>(sidebarPersistConfig, sidebarReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/FLUSH',
          'persist/PURGE',
          'persist/REGISTER',
        ],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
