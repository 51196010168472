import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IImpersonateData {
  companySlug?: string;
}

export interface IImpersonatorState {
  value: IImpersonateData | null;
}

// Define the initial state using that type
const initialState: IImpersonatorState = {
  value: null,
};

export const impersonatorSlice = createSlice({
  name: 'impersonator',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    impEnable: (state, action: PayloadAction<IImpersonateData>) => {
      state.value = action.payload;
    },
    impDisable: state => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { impEnable, impDisable } = impersonatorSlice.actions;
export const impersonatorReducer = impersonatorSlice.reducer;
