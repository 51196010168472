import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface ChatBubbleProps {
  className?: string;
  title?: ReactNode;
  message?: ReactNode;
  children?: ReactNode;
}

export interface AuthState {
  user?: {
    id: number;
    name: string;
    email: string;
    email_verified_at: string;
    role: string;
    created_at: string;
    updated_at: string;
  };
}

interface ISuperContext {
  bubble: ChatBubbleProps;
  setBubble: Dispatch<SetStateAction<ChatBubbleProps>>;
  showingNavigationDropdown: boolean;
  setShowingNavigationDropdown: Dispatch<SetStateAction<boolean>>;
  auth_: AuthState;
  setAuth_: Dispatch<SetStateAction<AuthState>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const defaultState: ISuperContext = {
  bubble: { title: '', message: '', className: '' },
  setBubble: () => {},
  showingNavigationDropdown: false,
  setShowingNavigationDropdown: () => {},
  auth_: {},
  setAuth_: () => {},
  isLoading: false,
  setIsLoading: () => {},
};

export const SuperContext = createContext<ISuperContext>(defaultState);

interface SuperProviderProps {
  children: ReactNode;
}

export const SuperProvider: React.FC<SuperProviderProps> = ({ children }) => {
  const [bubble, setBubble] = useState<ChatBubbleProps>({
    title: '',
    message: '',
    className: '',
  });

  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);

  const [auth_, setAuth_] = useState<AuthState>({});

  const [isLoading, setIsLoading] = useState(false);

  return (
    <SuperContext.Provider
      value={{
        bubble,
        setBubble,
        showingNavigationDropdown,
        setShowingNavigationDropdown,
        auth_,
        setAuth_,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </SuperContext.Provider>
  );
};

export const useSuperContext = () => useContext(SuperContext);
