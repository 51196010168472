import classNames from 'classnames';
import React from 'react';

const FullScreenLoader: React.FC = () => {
  return (
    <div
      className={classNames(
        'fixed inset-0 z-[51]',
        'flex items-center justify-center',
        'bg-black bg-opacity-50'
      )}
    >
      <div
        className={classNames(
          'animate-spin rounded-full h-32 w-32',
          'border-t-2 border-b-2',
          'border-ocseo-spanish-gray'
        )}
      ></div>
    </div>
  );
};

export default FullScreenLoader;
