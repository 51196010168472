import { SuperContext } from '@/Contexts/SuperContext';
import FullScreenLoader from '@/Layouts/FullScreenLoader';
import { Inertia } from '@inertiajs/inertia';
import React, { useContext, useEffect } from 'react';

const SuperLayout = ({ children }) => {
  const { isLoading, setIsLoading } = useContext(SuperContext);

  useEffect(() => {
    // Start the loader when Inertia starts
    const startHandler = Inertia.on('start', () => {
      setIsLoading(true);
    });

    // Keep loader until success event (new page is rendered)
    const successHandler = Inertia.on('success', () => {
      setIsLoading(false); // Hide loader once page is ready
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      startHandler();
      successHandler();
    };
  }, [setIsLoading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {children}
    </>
  );
};

export default SuperLayout;
