import { SuperProvider } from '@/Contexts/SuperContext';
import { UserAccessProvider } from '@/Contexts/UserAccessContext';
import SuperLayout from '@/Layouts/SuperLayout';

import { persistor, store } from '@/Redux/store';
import '../scss/app.scss';
import './bootstrap';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const appName = document.title || 'Laravel';

createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  resolve: name =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx')
    ),
  setup: ({ el, App, props }) => {
    const root = createRoot(el);

    root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SuperProvider>
            <SuperLayout>
              <UserAccessProvider>
                <App {...props} />
              </UserAccessProvider>
            </SuperLayout>
          </SuperProvider>
        </PersistGate>
      </Provider>
    );
  },
  progress: {
    color: '#4B5563',
  },
}).then();
